
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileInvoice,
	faMoneyBill,
	faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import Accessibility from "highcharts/modules/accessibility";
import "./DashboardAll.css";
import "../../App.css";
import { apiEndPoints } from "../../constants/apiEndPoints";

Highcharts3D(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);

const initialCardData = {
	CurrentEmployees: "",
	AverageTenure: "",
	TotalAttrition: "",
};

function Dashboard() {
	const [cardData, setCardData] = useState(initialCardData);
	const [departmentCounts, setDepartmentCounts] = useState([]);

	useEffect(() => {
		fetchDataFromApi();
	}, []);

	const fetchDataFromApi = () => {
		const token = localStorage.getItem("token");

		axios
			.get(apiEndPoints.Table_data, {
				headers: {
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				const data = response.data;
				setCardData(data);
				setDepartmentCounts(data.DepartmentCounts);
				renderChart(data.DepartmentCounts);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	const renderChart = (departmentCounts) => {
		Highcharts.chart("container", {
			chart: {
				type: "column",
				options3d: {
					enabled: true,
					alpha: 0,
					beta: 5,
					depth: 80,
					viewDistance: 20,
				},
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: departmentCounts.map((dept) => dept.DepartmentId),
			},
			yAxis: {
				title: {
					text: "Count",
				},
			},
			plotOptions: {
				column: {
					colorByPoint: true, // Enable colorByPoint
				},
			},
			legend: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			series: [
				{
					name: "Count",
					data: departmentCounts.map((dept) => dept.count),
				},
			],
		});
	};

	return (
		<div className="working__area close__sidebar">
			<div className="row">
				<div className="col-md-4">
					<div className="iq-card">
						<div className="iq-card-body iq-bg-primary rounded-4">
							<div className="d-flex align-items-center justify-content-between">
								<div className="rounded-circle iq-card-icon iq-primary">
									<FontAwesomeIcon icon={faFileInvoice} />
								</div>
								<div className="text-end">
									<h3 className="mb-0">
										<span className="counter">{cardData.CurrentEmployees}</span>
									</h3>
									<h5 className="">Current Employees</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="iq-card">
						<div className="iq-card-body iq-bg-success  rounded-4">
							<div className="d-flex align-items-center justify-content-between">
								<div className="rounded-circle iq-card-icon iq-success">
									<FontAwesomeIcon icon={faMoneyBill} />{" "}
								</div>
								<div className="text-end">
									<h3 className="mb-0">
										<span className="counter">{cardData.TotalAttrition}</span>
									</h3>
									<h5 className="">Total Attrition</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="iq-card">
						<div className="iq-card-body iq-bg-danger rounded-4">
							<div className="d-flex align-items-center justify-content-between">
								<div className="rounded-circle iq-card-icon iq-danger">
									<FontAwesomeIcon icon={faMoneyBillWave} />
								</div>
								<div className="text-end">
									<h3 className="mb-0">
										<span className="counter">{cardData.AverageTenure}</span>
									</h3>
									<h5 className="">Average Tenure</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="chart-sec">
				<div className="row">
					<div className="col-md-12">
						<h5 className="chart_heading p-2 mb-4">
							Department Wise Attrition Counts
						</h5>
						<div id="container" style={{ height: "400px" }}></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
