import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import "./App.css";
import Sidebar from "./Pages/Sidebar/Sidebar";
import Navigation from "./Pages/Navigation/Navigation";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import ForgotPas from "./Pages/ForgotPas";
import { ToastContainer } from "react-toastify";
import OutputFile from "./Pages/DashboardPg/OutputFile";
import StoredFile from "./Pages/DashboardPg/StoredFile";
import EmployeeAttrition from "./Pages/DashboardPg/EmployeeAttrition";
import Dashboard from "./Pages/DashboardPg/Dashboard";
import AnalyzeDocument from "./Pages/DashboardPg/AnalyzeDocument";
import { Provider } from "react-redux"; // Import Provider

import { useSelector, useDispatch } from "react-redux"; // Import hooks

import { createStore } from "redux";
import rootReducer from "./Reducers/reducers";
import FooterSec from "./Pages/Footer_sec/FooterSec";

function App() {
	const [loggedIn, setLoggedIn] = useState(
		sessionStorage.getItem("loggedIn") === "true"
	);
	const store = createStore(rootReducer);
	useEffect(() => {
		const storedLoggedIn = sessionStorage.getItem("loggedIn") === "true";

		if (storedLoggedIn) {
			setLoggedIn(true);
		}
	}, []);

	const handleLogout = () => {
		setLoggedIn(false);
		sessionStorage.setItem("loggedIn", "false");
	};

	return (
		<>
			<ToastContainer />
			<Router>
				<Provider store={store}>
					{loggedIn ? (
						<div className="whole__section">
							<div className="side__navigation" id="sidebar">
								<Sidebar />
							</div>
							<div className="content__area" id="content">
								<div className="header">
									<Navigation loggedIn={setLoggedIn} onLogout={handleLogout} />
								</div>
								<div className="main-container">
									<Routes>
										<Route
											path="/Employee-Attrition"
											element={
												loggedIn ? (
													<EmployeeAttrition />
												) : (
													<Navigate to="/login" />
												)
											}
										/>
										<Route
											path="/output-file"
											element={
												loggedIn ? <OutputFile /> : <Navigate to="/login" />
											}
										/>
										<Route
											path="/AnalyzeDocument"
											element={
												loggedIn ? (
													<AnalyzeDocument />
												) : (
													<Navigate to="/login" />
												)
											}
										/>
										{/* <Route
										path="/stored-file"
										element={
											loggedIn ? <StoredFile /> : <Navigate to="/login" />
										}
									/> */}

										<Route
											path="/dashboard"
											element={
												loggedIn ? <Dashboard /> : <Navigate to="/login" />
											}
										/>
										<Route path="/" element={<Navigate to="/dashboard" />} />
									</Routes>
								</div>
								{/* <div className="footer_sec">
									<FooterSec />
								</div> */}
							</div>
						</div>
					) : (
						<Routes>
							<Route
								path="/login"
								element={<Login setLoggedIn={setLoggedIn} />}
							/>
							{/* <Route path="/signup" element={<SignUp />} />
							<Route path="/ForgotPas" element={<ForgotPas />} /> */}
							<Route path="/" element={<Navigate to="/login" />} />
						</Routes>
					)}
				</Provider>
			</Router>
		</>
	);
}

export default App;
