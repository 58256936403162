import "../../App.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
	Upload,
	Button,
	message,
	Spin,
	Modal,
	Select,
	Table,
	Input,
} from "antd";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

export default function EmployeeAttrition() {
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const [file, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [searchText, setSearchText] = useState("");
	useEffect(() => {
		fetchTableData();
	}, []);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const handleUpload = async () => {
		const formData = new FormData();

		formData.append("file", file);

		try {
			setUploading(true);
			const token = localStorage.getItem("token");
			const response = await axios.post(apiEndPoints.CSV_upload, formData, {
				headers: {
					Authorization: `Token ${token}`,
					"Content-Type": "multipart/form-data",
				},
			});

			message.success("Files uploaded successfully");
			setFileList([]);
			document.querySelector(".ant-upload-list").innerHTML = "";
			setIsModalOpen(false);
		} catch (error) {
			console.error("Error occurred:", error);

			let errorMessage =
				"An error occurred while processing your request. Please try again later.";

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				errorMessage = error.response.data.message;
			}

			message.error(errorMessage);
		} finally {
			setUploading(false);
		}
	};
	const handleChange = (info) => {
		setFileList(info.file);
	};
	const handleSearch = (value) => {
		setSearchText(value);
	};
	const filteredData = tableData.filter((item) =>
		Object.values(item).some((val) =>
			val.toString().toLowerCase().includes(searchText.toLowerCase())
		)
	);
	const fetchTableData = async (response) => {
		debugger;
		try {
			const response = await fetch(apiEndPoints.Table_data);
			const data = await response.json();
			setTableData(data.Employees); // Assuming your API returns an array of objects
			// dispatch(updateAllTableData(data));
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const columns = [
		{
			title: "SR No",
			key: "sr_no",
			render: (text, record, index) => index + 1,
		},
		{
			title: "Employee ID",
			dataIndex: "empid",
			key: "empid",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Gender",
			dataIndex: "Gender",
			key: "Gender",
		},

		{
			title: "Department ID",
			dataIndex: "DepartmentId",
			key: "DepartmentId",
		},
		{
			title: "Tenure In Days",
			dataIndex: "JoiningYear",
			key: "joiningYear",
		},
		{
			title: "Previous Experience",
			dataIndex: "TotalPreviousExperiences",
			key: "TotalPreviousExperiences",
		},
		{
			title: "Attrition",
			dataIndex: "Prediction",
			key: "Prediction",
		},
	];
	return (
		<>
			<div className="working__area close__sidebar ">
				<div>
					{" "}
					<h3 className="blue mt-2">List of Employees Predicted Attrition</h3>
				</div>

				<div className="d-flex justify-content-between my-4">
					<div className="department_sec mx-2 w-25">
						<Input.Search
							placeholder="Search..."
							allowClear
							prefix={<SearchOutlined />}
							onChange={(e) => handleSearch(e.target.value)}
						/>
					</div>
					<div></div>
					<Button type="primary w-25 mx-2" onClick={showModal}>
						Upload CSV
						<FontAwesomeIcon className="mx-2" icon={faFileArrowUp} />
					</Button>
					<Modal
						title="Upload File"
						open={isModalOpen}
						className="text-center uploadModal"
						onCancel={handleCancel}
						width="55%"
						footer={[
							<Button
								key="upload"
								type="primary"
								onClick={handleUpload}
								disabled={file.length === 0 || uploading}>
								{uploading ? <Spin /> : "Upload"}
							</Button>,
						]}>
						<hr />
						<div className="background-sec ">
							<Upload.Dragger
								onChange={handleChange}
								// accept=".csv"
								beforeUpload={() => false}>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Select a CSV File to upload
									<br />
									Drag or drop files to upload
								</p>
							</Upload.Dragger>
						</div>
					</Modal>
				</div>
				<div className="table_content px-2">
					<Table
						dataSource={filteredData}
						columns={columns}
						// scroll={{ y: 1200 }}
					/>
				</div>
			</div>
		</>
	);
}
