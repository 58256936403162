const baseApi = "https://emp-attrition.brainvire.net/";
// const baseApi = "http://127.0.0.1:8000/";

export const apiEndPoints = {
	//CSV upload api

	CSV_upload: baseApi + "PredictFromData/",

	//Table Data view

	Table_data: baseApi + "EmployeeData/",
};
