// reducers.js
const initialState = {
	allTableData: [],
};

const fileReducer = (state = initialState, action) => {
	switch (action.type) {
		case "UPDATE_ALL_TABLE_DATA":
			return {
				...state,
				allTableData: action.payload,
			};
		default:
			return state;
	}
};

export default fileReducer;
