import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./Login.css";
import {
	MDBContainer,
	MDBCard,
	MDBCardBody,
	MDBCardImage,
	MDBRow,
	MDBCol,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

export default function Login({ setLoggedIn }) {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleLoginSuccess = (token) => {
		setLoggedIn(true);
		sessionStorage.setItem("loggedIn", "true");
		navigate("/dashboard");
	};
	const hardcodedCredentials = {
		Username: "admin",
		password: "1234",
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			if (
				values.Username === hardcodedCredentials.Username &&
				values.password === hardcodedCredentials.password
			) {
				message.success("Logged in successfully!");
				handleLoginSuccess();
				navigate("/Employee-Attrition");
			} else {
				message.error("Invalid Username or password");
			}
		} catch (error) {
			console.error("Error occurred:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<MDBContainer className="HRattrition mt-4">
			<MDBCard>
				<MDBRow className="g-0">
					<MDBCol md="6">
						<MDBCardImage
							src="/Images/4151253.jpg"
							alt="login form"
							className="rounded-start image-sec w-100"
						/>
					</MDBCol>

					<MDBCol md="6">
						<MDBCardBody className="d-flex flex-column">
							<div className="d-flex flex-row mt-2">
								<span className="h1 fw-bold mb-0 ">Welcome</span>
							</div>

							<h5 className="fw-normal my-4 " style={{ letterSpacing: "1px" }}>
								Sign into your account
							</h5>

							<Form
								name="loginForm"
								initialValues={{ remember: true }}
								onFinish={handleSubmit}
								className="mb-4">
								<Form.Item
									name="Username"
									rules={[
										{ required: true, message: "Please input your Username!" },
									]}
									className="mb-4">
									<Input
										prefix={<UserOutlined className="site-form-item-icon" />}
										placeholder="Username "
										size="large"
									/>
								</Form.Item>

								<Form.Item
									name="password"
									rules={[
										{ required: true, message: "Please input your password!" },
									]}
									className="mb-4">
									<Input.Password
										prefix={<LockOutlined className="site-form-item-icon" />}
										type="password"
										placeholder="Password"
										size="large"
									/>
								</Form.Item>

								<Form.Item className="mb-4 text-center">
									<Button
										type="primary"
										htmlType="submit"
										className="px-5 login-btn"
										size="large">
										{loading ? <Spin /> : "Log In"}
									</Button>
								</Form.Item>
							</Form>
						</MDBCardBody>
					</MDBCol>
				</MDBRow>
			</MDBCard>
			<div className="BVI_logo_sec">
				<img src="/Images/brainvire-logo.webp" alt="img" />
			</div>
		</MDBContainer>
	);
}
